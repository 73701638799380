module.exports = {
  animation: function () {
    var win = window;
    var sections = [];
    var triggerLocation = 0;
    // Let's animate everything in view if on Home page on mobile.
    (document.getElementById('home') && win.innerWidth <= 414) ? triggerLocation = 100 : triggerLocation = 75;
    // console.log(triggerLocation)
    var activationPercentage = triggerLocation / 100;
    // Let's Grab each element with animation-section class
    var elmList = document.querySelectorAll('.animation-section');
    // Visual Trigger line
    // var triggerLine = document.createElement('div');
    // triggerLine.setAttribute('id', 'line-trigger');
    // document.getElementById('app').appendChild(triggerLine);
    // var el = document.querySelector('#line-trigger');
    // el.setAttribute('style', 'background-color: red; position: absolute; left: 0px; width: 100%; height: 10px; z-index: 333;');
    // Let's an Array that stores objects for the Dom elements we need to trigger
    for (var i = 0; i < elmList.length; i++) {
      sections.push({
        element: elmList[i],
        position: elmList[i].getBoundingClientRect().top
      });
    }
    // Runs When Resize or scrolling & on initinal Page Load
    var displayHiddenSections = function () {
      // Find the Length of the Array
      var length = sections.length;
      // Let's check if we still need to run the code below still
      if (length > 0) {
        // Let's get the innerHeight of the window. Will * to get a precentage.
        var wh = window.innerHeight;
        // Grab where the window viewport is in the document
        var windowScroll = window.scrollY || document.documentElement.scrollTop;
        // Set text box position;
        // el.style.top = windowScroll + (wh * activationPercentage) + 'px';
        // Let's do Minus length, so we start from the last item, so 0 element is always the same.
        for (var i = length - 1; i >= 0; i--) {
          // Let's reference the array of element
          var elementPosition = sections[i].position;
          if (elementPosition < windowScroll + (wh * activationPercentage)) {
            // If condition is met remove hide class
            sections[i].element.classList.remove('hide');
            // Remove that element from the list as well
            sections.splice(i, 1);
          }
        }
      } else {
        // If all is done remove these two events listeners
        win.removeEventListener('scroll', displayHiddenSections);
        win.removeEventListener('resize', displayHiddenSections);
      }
    };
    win.addEventListener('scroll', displayHiddenSections);
    win.addEventListener('resize', displayHiddenSections);
    displayHiddenSections();
  }
};

var app = require('faction-client-site').App;
module.exports = {
  // Detects what link was clicked and runs conditions on it to see what action it should take.
  transition: function (e) {
    e.preventDefault();
    document.querySelector('body').classList.remove('open');
    if (e.currentTarget.hostname === window.location.hostname) {
      setTimeout(function () {
        app.router.navigate(e.currentTarget.getAttribute('href'), {
          trigger: true
        });
      }, 100);
      return false;
    } else if (e.currentTarget.target === '_blank') {
      var win = window.open(e.currentTarget.href, '_blank');
      win.focus();
    } else {
      window.location.href = e.currentTarget.href;
    }
  }
};

var $ = require('jquery');
var app = require('faction-client-site').App;
var NavView = require('./views/navView');
var homeView = require('./views/homeView');
var aboutView = require('./views/aboutView');
var teamView = require('./views/teamView');
var disclaimerView = require('./views/disclaimerView');
var FooterView = require('./views/footerView');
var error404View = require('./views/error_404');
var Router = require('faction-client-site').Router;
var UAParser = require('ua-parser-js');

module.exports = (function () {
  $(document).ready(function () {
    var nav = new NavView();
    var footer = new FooterView();
    var au = new UAParser();
    var name = au.getResult().browser.name;
    switch (name) {
      case 'IE':
      case 'Edge':
      document.documentElement.classList.add('is_ie');
      break;
      case 'Chrome':
      document.documentElement.classList.add('is_chrome');
      break;
      case 'Firefox':
      document.documentElement.classList.add('is_ff');
      break;
      case 'Safari':
      document.documentElement.classList.add('is_safari');
      break;
      case 'Android':
      document.documentElement.classList.add('is_android');
    }

    window.addEventListener('scroll', function (evt) {
      app.channel('browser').trigger('scroll', evt);
    });

    window.addEventListener('resize', function (evt) {
      app.channel('browser').trigger('resize', evt);
    });

    app.channel('browser').on('scroll:top', function () {
      app.channel('logs').trigger('console', 'scroll top!', 'public');
      window.scrollTo(0, 0);
    });

    app.channel('router').on('change', function (url, paths) {
      if (window.ga) {
        window.ga('send', 'pageview', {
          'page': url
        });
      }
    });

    app.run({
      router: new Router(),
      apiUrl: '/'
    });
  });
})();

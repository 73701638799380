var Backbone = require('backbone');
var pageTransition = require('../util/page-transition');

module.exports = Backbone.View.extend({
  el: '#footer',

  events: {
    'click a': 'navClick'
  },

  initialize: function (opts) {
    this.render();
  },

  render: function (data) {
  },

  navClick: function (e) {
    pageTransition.transition(e);
  },

  renderOut: function (cb) {
    setTimeout(function () {
      cb();
    }, 300);
  }
});

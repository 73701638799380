var View = require('faction-client-site').View;
var animationSection = require('../util/animations');
var pageTransition = require('../util/page-transition');
var checkLogo = require('../util/logo-check');

module.exports = View.create('template:error_404', {
  _el: '#four_oh_four',

  events: {
    'click a': 'navClick'
  },

  init: function () {
    this.app.channel('browser').trigger('scroll:top'); // scroll to the top
  },

  afterRender: function () {
    document.querySelector('body').classList.remove('open');
    document.getElementById('footer').classList.add('show');
    animationSection.animation();
    checkLogo.footerLogo();
  },

  renderOut: function (cb) {
    setTimeout(function () {
      document.getElementById('footer').classList.remove('show');
      cb();
    }, 300);
  },

  navClick: function (e) {
    pageTransition.transition(e);
  },

  destroy: function () {
    this.undelegateEvents();
    this.stopListening();
  }
});
